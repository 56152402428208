import { Button, Tooltip, IconButton, useTheme, styled } from "@mui/material";
import React, { ComponentProps } from "react";
import { Link, usePathname } from "@/navigation";
import { useFilterContextSelector } from "../Common/Filters/FilterContext";

const StyledLink = styled(Link)({
  width: "100%",
  display: "block",
});

const StyledButton = styled(Button)(
  ({ theme }) => `
  border-radius: 0;
  background-color: ${theme.palette.background.default};
  color: ${theme.palette.text.primary};
  font-weight: ${undefined};
  border-radius:8px;
  position: relative;
  height: 40px;

  &:hover {
    background-color: ${theme.palette.background.default};
    color: ${theme.palette.text.primary};
  }
    
  &[data-active="true"] {
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.background.default};
    font-weight: bold;
    &:hover {
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.background.default};
    }
  }
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => `
    background-color: ${theme.palette.background.default};
    color: ${theme.palette.text.primary};
    border-radius: ${theme.spacing(0.5)};
  border-radius:8px;
    &:hover {
      background-color: ${theme.palette.background.default};
      color: ${theme.palette.text.primary};
    }
    height: 40px;
    width: 40px;

    &[data-active="true"] {
      background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.background.default};
      &:hover {
        background-color: ${theme.palette.secondary.main};
        color: ${theme.palette.background.default};
      }
    }
  `,
) as typeof Button;

type NavItemDesktopProps = {
  children: React.ReactNode;
  icon: JSX.Element;
  isCollapsed?: boolean;
  onClose?: () => void;
} & ComponentProps<typeof Button>;

export default function NavItemDesktop(props: NavItemDesktopProps) {
  const { children, icon, isCollapsed, href, onClose, ...restProps } = props;
  const theme = useTheme();
  const pathname = usePathname();

  const [setIsFilterContainerOpen, setSelectedFilter] = useFilterContextSelector((ctx) => [
    ctx.setIsFilterContainerOpen,
    ctx.setSelectedFilter,
  ]);
  const handleCloseFilter = () => {
    onClose?.();
    setIsFilterContainerOpen(false);
    setSelectedFilter(undefined);
  };

  const iconElement = React.cloneElement(icon, {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    style: { fontSize: "20px" },
  });

  const isActive = href === pathname;

  if (isCollapsed) {
    return (
      <Tooltip placement="right" title={children} sx={{
        width: "fit-content",
      }}>
        {href ? (
          <StyledIconButton
            LinkComponent={StyledLink}
            href={isActive ? undefined : href}
            onClick={handleCloseFilter}
            data-active={isActive}
            {...restProps}
          >
            {iconElement}
          </StyledIconButton>
        ) : (
          <StyledIconButton onClick={handleCloseFilter} data-active={isActive} {...restProps}>
            {iconElement}
          </StyledIconButton>
        )}
      </Tooltip>
    );
  }
  if (href) {
    return (
      <StyledButton
        LinkComponent={StyledLink}
        href={isActive ? undefined : href}
        onClick={handleCloseFilter}
        fullWidth
        startIcon={iconElement}
        sx={{
          justifyContent: "left",
          minWidth: 0,
          textTransform: "none",
          "& .MuiButton-startIcon": {
            marginRight: theme.spacing(2),
            marginLeft: "0px",
          },
          "& .MuiButton-endIcon": {
            marginLeft: "0px",
          },
          "& .MuiButton-text": {
            alignSelf: "center",
            display: "flex",
            flexGrow: 1,
            pr: 1,
          },
          fontSize: theme.typography.body1.fontSize,
          fontWeight: isActive ? "bold" : theme.typography.body1.fontWeight,
          lineHeight: theme.typography.body1.lineHeight,
          whiteSpace: "nowrap",
        }}
        data-active={isActive}
        {...restProps}
      >
        {children}
      </StyledButton>
    );
  }
  return (
    <StyledButton
      fullWidth
      startIcon={iconElement}
      onClick={handleCloseFilter}
      sx={{
        justifyContent: "left",
        minWidth: 0,
        textTransform: "none",
        "& .MuiButton-startIcon": {
          marginRight: theme.spacing(2),
          marginLeft: "0px",
        },
        "& .MuiButton-endIcon": {
          marginLeft: "0px",
        },
        "& .MuiButton-text": {
          alignSelf: "center",
          display: "flex",
          flexGrow: 1,
          pr: 1,
        },
        fontSize: theme.typography.body1.fontSize,
        fontWeight: isActive ? "bold" : theme.typography.body1.fontWeight,
        lineHeight: theme.typography.body1.lineHeight,
        whiteSpace: "nowrap",
      }}
      data-active={isActive}
      {...restProps}
    >
      {children}
    </StyledButton>
  );
}
