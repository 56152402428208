import { KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { PageFilters } from "@/user/user-settings/common";
import { DEFAULT_FILTER_VALUE } from "@/user/user-settings/pageFilterHelper";
import { useCallback } from "react";

type InternalKeyOfPages = KeyOfPages;

const getFilterFromPathKey = (allPageFilters: PageFilters, pathKey?: InternalKeyOfPages) => {
  if (!pathKey) {
    return undefined;
  }
  if (pathKey in allPageFilters) {
    return allPageFilters[pathKey];
  }
  return DEFAULT_FILTER_VALUE;
};

const useGetFilterNavigation = (pathKey?: InternalKeyOfPages) => {
  const allPageFilters = useUserSettingsContextSelector((ctx) => ctx.allPageFilters);
  return getFilterFromPathKey(allPageFilters, pathKey);
};

export const useGetNavFilterByPathkey = () => {
  const allPageFilters = useUserSettingsContextSelector((ctx) => ctx.allPageFilters);
  const getFilterValuesByPathKey = useCallback(
    (selectedNavFilter: string, pathKey?: InternalKeyOfPages) => {
      const allFilters = getFilterFromPathKey(allPageFilters, pathKey);
      return allFilters?.find((val) => val.name === selectedNavFilter)?.value;
    },
    [allPageFilters],
  );
  return getFilterValuesByPathKey;
};

export default useGetFilterNavigation;
