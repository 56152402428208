import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import useGetFilterNavigation, { useGetNavFilterByPathkey } from "@/hooks/useGetFilterNavigation";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { DEFAULT_NAME } from "@/user/user-settings/pageFilterHelper";
import useGetPathKey from "@/hooks/useGetPathKey";
import { generateRandomUintNumber } from "@/utis/generateRandomUintNumber";
import { FilterEnum } from "./FilterList";
import { CommonFilterType, getDefaultFilterValuesByPathKey, KeyOfPages } from "./commonFilterType";

export type AdvanceFilterType =
  | {
    filterKey: string;
    displayName: string;
    icon?: React.ReactElement;
    parentFilter: FilterEnum;
  }
  | undefined;

export type UserAdvanceFilterType = {
  filterKey: string;
  filterEnum: FilterEnum;
  filters: Omit<Partial<CommonFilterType>, "advanceFilter">;
  requestHash: number;
};

export type UserAdvanceFilterArrayType = UserAdvanceFilterType[] | undefined;

export type FilterContextValue = {
  filterState: CommonFilterType;
  setFilter: (val: Partial<CommonFilterType>) => void;
  setAllFilter: (val: CommonFilterType) => void;
  resetCurrentFilter: () => void;
  setDefaultFilter: () => void;
  isFilterContainerOpen: boolean;
  setIsFilterContainerOpen: (val: boolean) => void;
  selectedFilter: FilterEnum | undefined;
  setSelectedFilter: (val: FilterEnum | undefined) => void;
  advanceFilter: AdvanceFilterType;
  setAdvanceFilter: (val: AdvanceFilterType | undefined) => void;
  userAdvanceFilter: UserAdvanceFilterArrayType;
  queryStatePathKey: KeyOfPages;
  getAdvanceFilterState: (filterKey: string) => Omit<Partial<CommonFilterType>, "advanceFilter"> | undefined;
};

const FilterContext = createContext<FilterContextValue>(undefined!);
export default FilterContext;

export function useFilterContextSelector<Selected>(selector: (value: FilterContextValue) => Selected) {
  return useContextSelector(FilterContext, selector);
}

type FilterProviderProps = {
  children: React.ReactNode;
};

export function FilterProvider({ children }: FilterProviderProps) {
  const pathKey = useGetPathKey();
  const [selectedNavFilter, setSelectedNavFilter] = useUserSettingsContextSelector((ctx) => [
    ctx.selectedNavFilter,
    ctx.setSelectedNavFilter,
  ]);
  const pageFilters = useGetFilterNavigation(pathKey);
  const isCollapsed = useUserSettingsContextSelector((ctx) => ctx.globalSettings.isCollapsed);
  const setIsCollapsed = useUserSettingsContextSelector((ctx) => ctx.setIsCollapsed);
  const getFilterValuesByPathKey = useGetNavFilterByPathkey();
  const [queryStatePathKey, setQueryStatePathKey] = useState(pathKey);
  const [filterState, setFilterState] = useState<CommonFilterType>(
    getFilterValuesByPathKey(selectedNavFilter, pathKey) || getDefaultFilterValuesByPathKey(pathKey),
  );
  const overriddenBy = pageFilters?.find((val) => val.name === DEFAULT_NAME)?.overriddenBy;

  const [isFilterContainerOpen, setIsFilterContainerOpenState] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterEnum>();

  const [advanceFilter, setAdvanceFilter] = useState<AdvanceFilterType>();

  const setFilter = useCallback(
    (val: Partial<CommonFilterType>) => {
      setFilterState({ ...filterState, ...val });
    },
    [filterState],
  );
  const setAllFilter = useCallback((val: CommonFilterType) => {
    setFilterState(val);
  }, []);

  // Earlier we set take to 50 as default in commonStateVariable, but we need it undefined for restrictions
  // useEffect(() => {
  //   if (filterState.take === MAX_TAKE_DEFAULT) {
  //     setFilter({ take: undefined });
  //   }
  // }, [filterState.take, filterState]);

  const setIsFilterContainerOpen = useCallback(
    (val: boolean) => {
      setIsFilterContainerOpenState(val);
      if (val === false) {
        setIsCollapsed(isCollapsed);
      }
    },
    [isCollapsed, setIsCollapsed, setIsFilterContainerOpenState],
  );

  const resetCurrentFilter = useCallback(() => {
    setFilterState(getDefaultFilterValuesByPathKey(pathKey));
  }, [pathKey]);

  const setDefaultFilter = useCallback(() => {
    setFilterState(getFilterValuesByPathKey(selectedNavFilter, pathKey) || getDefaultFilterValuesByPathKey(pathKey));
  }, [getFilterValuesByPathKey, pathKey, selectedNavFilter]);

  // Whenever route is changed, Set the fitler to default of the new route
  useEffect(() => {
    if (pathKey !== queryStatePathKey) {
      setFilterState(
        getFilterValuesByPathKey(overriddenBy || DEFAULT_NAME, pathKey) || getDefaultFilterValuesByPathKey(pathKey)
      );
      setQueryStatePathKey(pathKey);
      setSelectedNavFilter(overriddenBy || DEFAULT_NAME, false);
    }
  }, [getFilterValuesByPathKey, overriddenBy, pathKey, queryStatePathKey, setSelectedNavFilter]);

  const userAdvanceFilter = useMemo(() => {
    if (!filterState.advanceFilter) return undefined;
    return (JSON.parse(filterState.advanceFilter) as UserAdvanceFilterArrayType)?.map((advFil) =>
      advFil.requestHash ? advFil : { ...advFil, requestHash: generateRandomUintNumber() },
    );
  }, [filterState.advanceFilter]);

  const getAdvanceFilterState = useCallback(
    (filterKey: string) => userAdvanceFilter?.find((uesrAdvFil) => uesrAdvFil.filterKey === filterKey)?.filters,
    [userAdvanceFilter],
  );

  const providerValue: FilterContextValue = useMemo(
    () => ({
      filterState,
      setFilter,
      setAllFilter,
      resetCurrentFilter,
      setDefaultFilter,
      isFilterContainerOpen,
      setIsFilterContainerOpen,
      selectedFilter,
      setSelectedFilter,
      advanceFilter,
      setAdvanceFilter,
      userAdvanceFilter,
      queryStatePathKey,
      getAdvanceFilterState,
    }),
    [
      filterState,
      setFilter,
      setAllFilter,
      resetCurrentFilter,
      setDefaultFilter,
      isFilterContainerOpen,
      setIsFilterContainerOpen,
      selectedFilter,
      advanceFilter,
      userAdvanceFilter,
      queryStatePathKey,
      getAdvanceFilterState,
    ],
  );

  return <FilterContext.Provider value={providerValue}>{children}</FilterContext.Provider>;
}